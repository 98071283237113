<template>
    <div id="distribution">
        <Layout>

            <form-wizard :start-index="0" ref="wizard" color="#F9A707" style="
                margin-top: 2rem;
                " @on-complete="onComplete">
                <!-- Tab Content 1 -->
                <tab-content title="General Info" :before-change="validateAsync">
                    <!--  -->
                    <template>
                        <b-form>
                            <div class="card new-card" style="
                                        margin-top: 30px;
                                        box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%) !important;
                                        border-radius: 5px;
                                    ">
                                <div class="card-body">
                                    <div class="card">
                                        <div class="card-body">

                                            <i class="fa fa-truck" style="color: #F9A707; margin-right: 1rem;"></i><b>
                                                Data
                                                Truck</b>
                                            <hr>
                                            <div class="row">
                                                <div class="col-3">
                                                    <label>Truck Type
                                                        <span style="color: red">*</span>
                                                    </label>
                                                    <v-select :options="truckType" item-text="name" item-value="id"
                                                        v-model="form.truck_type" :reduce="(option) => option.id"
                                                        label="name" :required="true" :class="formRequired.truck_type">
                                                    </v-select>

                                                </div>
                                                <div class="col-3">
                                                    <label>Container Size <span class="text-danger">*</span></label>
                                                    <v-select :options="continerSize" item-text="name" item-value="id"
                                                        v-model="form.container_size" :reduce="(option) => option.id"
                                                        :class="formRequired.container_size" label="name">
                                                    </v-select>

                                                </div>
                                                <div class="col-3">
                                                    <label>Choose Truck <span class="text-danger">*</span></label>
                                                    <v-select :options="chooseTruck" item-text="name" item-value="id"
                                                        v-model="form.choose_truck" :reduce="(option) => option.id"
                                                        :class="formRequired.choose_truck" label="name">
                                                    </v-select>
                                                </div>
                                                <div class="col-3">
                                                    <label>Seal Number <span class="text-danger">*</span></label>
                                                    <input type="number" v-model="form.seal_number" name="seal_number"
                                                        id="" min="0" class="form-control"
                                                        :class="formRequired.seal_number" style="height: 34px;">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <label>Container Number <span class="text-danger">*</span></label>
                                                    <input type="number" name="container_number" class="form-control"
                                                        :class="formRequired.container_number" style="height: 34px;"
                                                        v-model="form.container_number">
                                                </div>
                                                <div class="col-3">
                                                    <label>Driver Name <span class="text-danger">*</span></label>
                                                    <v-select :options="driverName" item-text="name" item-value="id"
                                                        v-model="form.driver_name" :reduce="(option) => option.id"
                                                        :class="formRequired.driver_name" label="name">
                                                    </v-select>
                                                </div>
                                            </div>


                                            <div class="mt-4">
                                                <i class="fa fa-address-card"
                                                    style="color: #F9A707; margin-right: 1rem;"></i><b>
                                                    Delivery Expense</b>
                                            </div>
                                            <hr>
                                            <div class="row">
                                                <div class="col-3">
                                                    <label>Cash / Bank <span class="text-danger">*</span></label>
                                                    <v-select :options="cashBank" item-text="name" item-value="id"
                                                        v-model="form.cash_bank" :reduce="(option) => option.id"
                                                        :class="formRequired.cash_bank" label="name">
                                                    </v-select>
                                                </div>
                                                <div class="col-3">
                                                    <label for="">Autonumber<span class="text-danger">*</span></label>
                                                    <div class="input-group mb-3">
                                                        <select v-if="inputAutonumber" name="" id=""
                                                            class="form-control" style="width: auto;"
                                                            v-model="form.autonumber">
                                                            <option value="" disabled>Select Auto Number</option>
                                                            <option v-for="(item, i) in deliveryExpenseAn" :key="i"
                                                                :value="item.id">
                                                                {{ item.name }} </option>
                                                        </select>

                                                        <input v-else type="text" class="form-control"
                                                            :class="formRequired.autonumber"
                                                            v-model="form.autonumber_manual">

                                                        <div class="input-group-append">
                                                            <button class="btn btn-success"
                                                                @click.prevent="changeInputAutonumber"><i
                                                                    class="fa fa-pencil-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <label for="">Date <span class="text-danger">*</span></label>
                                                    <date-picker v-model="form.date" type="date" lang="en"
                                                        :class="formRequired.date">
                                                    </date-picker>
                                                </div>
                                                <div class="col-3">
                                                    <label>Item Account<span class="text-danger">*</span></label>
                                                    <v-select :options="itemAccount" item-text="name" item-value="id"
                                                        v-model="form.item_account" :reduce="(option) => option.id"
                                                        :class="formRequired.item_account" label="name">
                                                    </v-select>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-4">
                                                    <label for="">Amount <span class="text-danger">*</span></label>
                                                    <input type="text" v-model="form.amount_price" name="amout" id=""
                                                        class="form-control" @input="onAmount"
                                                        :class="formRequired.amount" min="0">
                                                </div>
                                                <div class="col-4">
                                                    <b-form-group id="input-group-11" label="Memo" label-for="input-11">
                                                        <b-form-input id="input-11" v-model="form.memo" type="text"
                                                            :class="formRequired.memo" required>
                                                        </b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-4">
                                                    <b-form-group id="input-group-11" label="Remarks"
                                                        label-for="input-11">
                                                        <b-form-input id="input-11" v-model="form.remarks" type="text"
                                                            :class="formRequired.remarks" required>
                                                        </b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </b-form>


                    </template>

                    <div class=wizard-footer-left>
                        <b-button class="btn-cancel" style="position: absolute; margin-top: 11px;"
                            @click.prevent="onKembali">
                            Cancel
                        </b-button>

                    </div>
                </tab-content>
                <!-- End Tab Content 1 -->

                <!-- Tab Content 2 -->
                <tab-content title="Loading">
                    <template>
                        <div class="add-loading mt-2">
                            <div class="row">
                                <div class="col-1">
                                    <img src="../../../../../assets/images/truck.png" alt="" class="image-truck">
                                </div>
                                <div class="col-8">
                                    <div class="title ml-4">
                                        <b>Data Loading</b>
                                        <br>
                                        Add your Loading
                                    </div>
                                </div>
                                <div class="col-2 text-right">
                                    <button class="btn btn-new-transaction" @click="addLoading"><i
                                            class="fas fa-plus"></i> Add Loading</button>
                                </div>
                            </div>
                        </div>


                        <div v-if="form2.length === 0">
                            <div class="box">
                                <img src="../../../../../assets/images/logistics1.png" alt="" width="60px">
                                <br>
                                No data loading yet
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="(loading, index) in form2" :key="index">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="text-left">
                                                    <i class="fa fa-truck"
                                                        style="color: #F9A707; margin-right: 1rem;"></i><b> Loading
                                                        Truck</b>
                                                </div>
                                            </div>
                                            <input type="hidden" name="" v-model="loading.id_element">
                                            <div class="col-6">
                                                <div class="text-right">
                                                    <a href="javascript:void(0)" @click="deleteFormLoading(index,loading)">
                                                        <i class="fa fa-trash-alt"
                                                            style="color: red; margin-right: 1rem"></i> <span
                                                            class="text-danger"> Delete Loading</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <b-form>
                                            <div class="row">
                                                <div class="col-3">
                                                    <label for="">Loading<span class="text-danger">*</span></label>
                                                    <v-select :options="optionLoading" item-text="name" item-value="id"
                                                        v-model="form2[index].loading" :reduce="(option) => option.id"
                                                        label="name" @input="setSelectedLoading($event, index)">
                                                    </v-select>
                                                </div>
                                                <input type="hidden" name="" v-model="form2[index].name">
                                                <div class="col-9">
                                                    <label for="">Detail Address Loading<span
                                                            class="text-danger"></span></label>
                                                    <input type="text" name="dal" id="" v-model="form2[index].dal"
                                                        class="form-control">
                                                </div>
                                            </div>

                                            <div v-for="(row, k) in form2[index].rowadd" :key="k">
                                                <div class="row mt-2">
                                                    <div class="col-3">
                                                        <label for="">Item Name<span
                                                                class="text-danger">*</span></label>

                                                        <div class="row">
                                                            <div class="col-10">
                                                                <v-select v-if="row.item_set" :options="masterItem"
                                                                    item-text="name" item-value="id"
                                                                    v-model="row.item_id"
                                                                    :reduce="(option) => option.id" label="name"
                                                                    @input="getNameItem(row.item_id, index, k)">
                                                                </v-select>

                                                                <input v-else type="text" v-model="row.item_name"
                                                                    class="form-control" />
                                                            </div>
                                                            <div class="col-2">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-success"
                                                                        @click.prevent="changeInputItemNameLoading(index, k)"><i
                                                                            class="fa fa-pencil-alt"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div class="col-5">
                                                        <label for="">Measurment<span
                                                                class="text-danger"></span></label>
                                                        <div class="row">
                                                            <div class="col-3"><input v-model="row.m1" type="text"
                                                                    name="" id="" class="form-control"
                                                                    @input="onMeasurmentLoading(index, k)"></div>
                                                            <div class="col-1">X</div>
                                                            <div class="col-3"><input v-model="row.m2" type="text"
                                                                    name="" id="" class="form-control"
                                                                    @input="onMeasurmentLoading(index, k)"></div>
                                                            <div class="col-1">X</div>
                                                            <div class="col-3"><input v-model="row.m3" type="text"
                                                                    name="" id="" class="form-control"
                                                                    @input="onMeasurmentLoading(index, k)"></div>
                                                        </div>
                                                        <!-- <div class="row">
                                                            <div class="col-3">
                                                                <span class="text-success">{{ totalMeasurment(index,k)
                                                                }}
                                                                    CM</span>


                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="col-2">
                                                        <label for="">Weight (KG)<span
                                                                class="text-danger">*</span></label>
                                                        <input type="text" name="weight" id="" v-model="row.weight"
                                                            class="form-control" @input="onWeightLoading(index, k)">
                                                    </div>
                                                    <div class="col-2">
                                                        <label for="">Qty<span class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <input v-model="row.qty" type="number" id="" name=""
                                                                class="form-control" value="0"
                                                                @input="onQtyLoading(index, k)">
                                                            <div class="input-group-append">
                                                                <div v-if="form2[index].rowadd[k].length === 1">
                                                                    <button type="button" class="btn btn-danger"
                                                                        disabled><i class="fa fa-minus"></i></button>
                                                                </div>
                                                                <div v-else>
                                                                    <button class="btn btn-danger"
                                                                        @click.prevent="removeRow(index, k, row)"><i
                                                                            class="fa fa-minus"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-2"><small>Detail Calculation</small></div>
                                                <div class="col-2"><b>Sub total : </b></div>
                                                <div class="col-2">Measurment : <span class="text-success">{{
                                                        totalMeasurmentCBMLoading(index)
                                                }}</span>
                                                </div>
                                                <div class="col-2">Weight : <span class="text-success">{{
                                                        totalWeightLoading(index)
                                                }}
                                                        KGS</span></div>
                                                <div class="col-2">Qty : <span class="text-success">{{
                                                        totalQtyLoading(index)
                                                }}</span></div>
                                                <div class="col-2 text-right">
                                                    <span class="text-success">
                                                        <a href="#" @click.prevent="addRow(index)">
                                                            <span>Add New</span>
                                                            <i class="fa fa-plus ml-2"></i>
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>

                                        </b-form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </tab-content>
                <!-- End Tab Content 2 -->

                <!-- Tab Content 3 -->
                <tab-content title="Unloading"  :before-change="form3.length > 0 ? validateForm3Async : validateEmptyForm3">
                    <template>
                        <div class="add-unloading mt-2">
                            <div class="row">
                                <div class="col-1">
                                    <img src="../../../../../assets/images/truck.png" alt="" class="image-truck">
                                </div>
                                <div class="col-8">
                                    <div class="title ml-4">
                                        <b>Data Unloading</b>
                                        <br>
                                        Add your Unloading
                                    </div>
                                </div>
                                <div class="col-2 text-right">
                                    <button class="btn btn-new-transaction" @click="addUnloading"><i
                                            class="fas fa-plus"></i> Add Unloading</button>
                                </div>
                            </div>
                        </div>

                        <div v-if="form3.length === 0">
                            <div class="box">
                                <img src="../../../../../assets/images/logistics1.png" alt="" width="60px">
                                <br>
                                No data loading yet
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="(unloadings, index) in form3" :key="index">
                                <!-- <Unloading @delete-form-unloading="deleteFormUnloading($event)" :index=index
                                    :loading=loading ref="unloadingData" :form="unloading">
                                </Unloading> -->
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="text-left">
                                                    <i class="fa fa-truck"
                                                        style="color: #F9A707; margin-right: 1rem;"></i><b> Unloading
                                                        Truck</b>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="text-right">
                                                    <a href="#" @click="deleteFormUnloading(index, form3[index])">
                                                        <i class="fa fa-trash-alt"
                                                            style="color: red; margin-right: 1rem"></i> <span
                                                            class="text-danger"> Delete Unloading</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>

                                        <b-form>
                                            <div class="row">

                                                <div class="col-4">
                                                    <label for="">Unloading<span class="text-danger">*</span></label>
                                                    <v-select :options="optionUnloading" item-text="name"
                                                        item-value="id" v-model="form3[index].unloading"
                                                        :reduce="(option) => option.id" label="name"
                                                        @input="setSelectedUnloading($event, index)">
                                                    </v-select>
                                                </div>
                                                <div class=" col-4">
                                                    <label for="">Autonumber<span class="text-danger">*</span></label>
                                                    <div class="input-group mb-3">

                                                        <select v-if="form3[index].inputUnloadingAutonumber" name=""
                                                            id="" class="form-control" style="width: 210px;"
                                                            v-model="form3[index].autonumber"
                                                            :class="form3Required[index].inputUnloadingAutonumber">
                                                            <option value="" disabled selected>Select Auto Number
                                                            </option>
                                                            <option v-for="(item, i) in UnloadingAn" :key="i"
                                                                :value="item.id">
                                                                {{ item.name }} </option>
                                                        </select>


                                                        <input v-else type="text" class="form-control"
                                                            style="width: 210px;"
                                                            v-model="form3[index].autonumber_manual">

                                                        <div class="input-group-append">
                                                            <button class="btn btn-success"
                                                                @click.prevent="changeInputUnloadingAutonumber(index)"><i
                                                                    class="fa fa-pencil-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <label for="">Detail Address Unloading<span
                                                            class="text-danger"></span></label>
                                                    <input v-model="form3[index].dau" type="text" name="" id=""
                                                        class="form-control">
                                                </div>
                                            </div>



                                            <div v-for="(row, k) in form3[index].rowadd" :key="k" class="mb-3">

																							<div class="row">

																								<div class="col-4">
																											<label for="">Loading<span class="text-danger">*</span></label>
																											<v-select :options="loading" item-text="name" item-value="id"
																													v-model="row.loading"
																													:reduce="(option) => option.loading" label="name"
																													@input="setSelectedLoadingInUnloading(index,k)">
																											</v-select>
																									</div>
																									<div class="col-4">
																											<!-- (kasih keterangan manual atau option item) -->
																											<label for="">Item Name <span
																															class="text-danger">*</span></label>
																											<select v-model="row.val_item_id" name="item_name" id=""
																													class="form-control"
																													@change="getMeasurmentLoading(index, k)">
																													<option value="" disabled> Select Options Item</option>
																													<option v-for="(item, i) in row.item_name" :key="i"
																															:value="item.item_id">
																															{{ item.item_name }} </option>
																											</select>
																									</div>
																									<div class="col-4">
																											<!-- (kasih keterangan manual atau option item) -->
																											<label for="">Type <span class="text-danger">*</span></label>
																											<select v-model="row.type" name="item_name" id=""
																													class="form-control" style="center-text"
																													@change="onTotal(index, k)">
																													<option value="QTY">QTY</option>
																													<option value="KGS">KGS</option>
																													<option value="CBM">CBM</option>
																											</select>
																									</div>
																							</div>


                                                <div class="row mt-2">
                                                    <div class="col-3">
                                                        <label for="">Price<span class="text-danger">*</span></label>
                                                        <input v-model="row.price" type="text" name="price" id=""
                                                            min="0" class="form-control" @input="onPrice(index, k)">
                                                    </div>
                                                    <div class="col-2">
                                                        <label for="">Qty<span class="text-danger">*</span></label>
                                                        <input v-model="row.qty" type="text" name="" id="" min="0"
                                                            :class="form3[index].rowadd[k].required_qty"
                                                            class="form-control" @input="onQty(k, index)">
                                                    </div>

                                                    <div class="col-2">
                                                        <label for="">Measurment<span
                                                                class="text-danger"></span></label>
                                                        <input v-model="row.measurment" type="text" name="measurment"
                                                            id="" class="form-control" readonly
                                                            style="background-color: #e0e0e0;">
                                                    </div>


                                                    <div class="col-2">
                                                        <label for="">Weight<span class="text-danger">*</span></label>
                                                        <input v-model="row.weight" type="text" name="weight" id=""
                                                            class="form-control" readonly
                                                            style="background-color: #e0e0e0;">
                                                    </div>


                                                    <div class="col-3">
                                                        <label for="">Total<span class="text-danger">*</span></label>
                                                        <div class="input-group">
                                                            <input v-model="row.total" type="text" id="" name=""
                                                                class="form-control" readonly
                                                                style="background-color: #e0e0e0; color: black;">
                                                            <div class="input-group-append">
                                                                <div v-if="form3[index].rowadd.length === 1">
                                                                    <button type="button" class="btn btn-danger"
                                                                        disabled><i class="fa fa-minus"></i></button>
                                                                </div>
                                                                <div v-else>
                                                                    <button class="btn btn-danger"
                                                                        @click.prevent="removeFormUnloading(index, k, row)"><i
                                                                            class="fa fa-minus"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-2"><small>Data Calculation </small><b>Sub total : </b>
                                                </div>
                                                <div class="col-2">Measurment : <span class="text-success">{{
                                                        totalMeasurmentCBM(index)
                                                }}</span></div>
                                                <div class="col-2">Weight : <span class="text-success">{{
                                                        totalWeight(index)
                                                }} KGS</span>
                                                </div>
                                                <div class="col-1">Qty : <span class="text-success">{{ totalQty(index)
                                                }}</span></div>
                                                <div class="col-3">Total Price : <span class="text-success">{{
                                                        totalPrice(index)
                                                }}</span>
                                                </div>
                                                <div class="col-2 text-right"><span class="text-success">
                                                        <a href="#" @click.prevent="addFormUnloading(index)">
                                                            <span>Add New</span>
                                                            <i class="fa fa-plus ml-2"></i>
                                                        </a>
                                                    </span></div>
                                            </div>
                                        </b-form>
                                        <!-- <button class="btn-next" @click="getLoading(loading)">Next</button> -->

                                    </div>
                                </div>
                            </div>

                        </div>
                    </template>
                </tab-content>
                <!-- End Tab Content 3 -->

                <button slot="prev" class="btn-cancel">Previous</button>
                <button slot="next" class="btn-next" @click.prevent="onCompleteForm">Next</button>


                <!-- <button slot=" finish" class="btn-finish">Save Transaction</button> -->
            </form-wizard>


            <div class="row" style="padding: 10px 20px 10px;">
                <div class="col-12">
                    <div class="card new-card"
                        style="box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%) !important; border-radius: 5px;">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-2 pr-1">
                                    <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                        <label class="bold">Sales :</label>
                                        <label class="">IDR:000,000</label>
                                    </div>
                                </div>
                                <div class="col-2 pr-1">
                                    <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                        <label class="bold">Cost :</label>
                                        <label class="">IDR:000,000</label>
                                    </div>
                                </div>
                                <div class="col-2 pr-1">
                                    <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                        <label class="bold">Margin :</label>
                                        <label class="">IDR:000,000</label>
                                    </div>
                                </div>
                                <div class="col-2 pr-1">
                                    <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                        <label class="bold">Refund :</label>
                                        <label class="">IDR:000,000</label>
                                    </div>
                                </div>
                                <div class="col-2 pr-1">
                                    <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                        <label class="bold">Balance Sheet :</label>
                                        <label class="">IDR:000,000</label>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card border pl-2 pt-1 pr-2" style="margin-bottom: 0px">
                                        <label class="bold">Deposit :</label>
                                        <label class="">IDR:000,000</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    </div>
</template>


<script>
import Layout from '@layouts/main'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import DatePicker from 'vue2-datepicker'
import { authMethods } from '@state/helpers'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

import axios from 'axios'

// import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

// import FormLoading from '@components/JobOrder/Distribution/FormLoading.vue'

export default {
    components: {
        Layout,
        FormWizard,
        TabContent,
        DatePicker,
    },
    data() {
        return {
            formRequired: {
                truck_type: false,
                container_size: false,
                choose_truck: false,
                seal_number: false,
                container_number: false,
                driver_name: false,

                cash_bank: false,
                autonumber: false,
                date: false,
                amount: false,
                item_account: false,
                memo: false,
                remarks: false,
            },
            form: {
                truck_type: '',
                container_size: '',
                choose_truck: '',
                seal_number: '',
                container_number: '',
                driver_name: '',
                cash_bank: '',
                autonumber: '',
                autonumber_manual: '',
                date: '',
                amount_price: '',
                amount: '',
                item_account: '',
                memo: '',
                remarks: '',

                // add this id for job order and edit this id for job order distribution
                id_job_order: this.$route.params.id,
            },
            form2: [],
            form3: [],
            form3Required: [],
						formDelete: [],
            itemloadings: [{
                id_element: '',
            }],
            formUnloading: true,
            itemunloadings: [],
            showFinishModal: false,
            //
            inputAutonumber: true,
            truckType: [],
            continerSize: [],
            chooseTruck: [],
            driverName: [],
            cashBank: [],
            itemAccount: [],
            itemMaster: [],
            loading: [],
            unloading: [],
            uniqueKey: 0,
            //
            optionLoading: [],
            optionUnloading: [],
            rowadd: [],
            dataDistribution: {
                form: {},
                form2: [],
                form3: [],
								formDelete: [],
            },
            masterItem: [],
            deliveryExpenseAn: [],
            UnloadingAn: []
        }
    },

    mounted() {
        this.listTruckType()
        this.listContainerSize()
        this.listChooseTruck()
        this.listDriverName()
        this.listCashBank()
        this.listItemAccount()
        this.listAddressLoading()
        this.listItem()
        this.listMasterItem()
        this.listDeliveryExpenseAn()
        this.listUnloadingAn()
        if (this.$route.name === 'JobOrder Edit Distribution') {
            this.getDataDistribution()
        }
    },
    methods: {
        ...authMethods,

        errorAlertValidation: function (message = 'Terjadi Kesalahan') {
            this.$swal({
                title: message,
                type: 'error',
                timer: '2000',
                toast: true,
                position: 'bottom-end',
            })
        },
        ...mapActions('jobOrderDistribution', [
            'getTypeOptions',
        ]),
        onComplete(data) {
						// this.validateForm3Async()
            this.dataDistribution.form = this.form
            this.dataDistribution.form2 = this.form2
            this.dataDistribution.form3 = this.form3


            if (this.$route.name === 'JobOrder Edit Distribution') {
							this.dataDistribution.formDelete = this.formDelete
                axios.post(this.$store.state.rootApi + "/distribution/update", this.dataDistribution, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((res) => {
											console.log(res)
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                        })
                        Toast.fire({
                            icon: 'success',
                            title: res.message,
                        })

												this.$router.push({
													name: 'JobOrder Details',
													params: {
														id: res.data.id_job_order,
													},
												})

                    })
                    .catch((err) => {
                        if (err.response.status === 422) {
                            let title = []
                            for (let field of Object.values(err.response.data.message)) {
                                title.push(field)
                            }
                            Swal.fire({
                                title: 'Error',
                                html: title.join("<br/><br/>"),
                                icon: 'error',
                                confirmButtonColor: 'rgb(221, 107, 85)',
                                confirmButtonText: 'Ok',
                            })
                        } else {
                            this.errorAlert('Failed save data')
                        }
                    })

            } else {
                axios.post(this.$store.state.rootApi + "/distribution/store", this.dataDistribution, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((res) => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            },
                        })
                        Toast.fire({
                            icon: 'success',
                            title: res.message,
                        })

                        window.history.back();
                    })
                    .catch((err) => {

                        if (err.response.status === 422) {
                            let title = []
                            for (let field of Object.values(err.response.data.message)) {
                                title.push(field)
                            }
                            Swal.fire({
                                title: 'Error',
                                html: title.join("<br/><br/>"),
                                icon: 'error',
                                confirmButtonColor: 'rgb(221, 107, 85)',
                                confirmButtonText: 'Ok',
                            })
                        } else {
                            this.errorAlert('Failed save data')
                        }
                    })
            }
        },
        onCompleteForm(tabIndex) {
            // console.log(tabIndex)
            this.loading = JSON.parse(JSON.stringify(this.form2))

            if (this.form3.length > 0) {
                for (let index = 0; index < this.form3.length; index++) {
                    this.loading.forEach((element) => {
                        if (element.loading === this.form3[index].loading) {
                            element.rowadd.forEach(val => {
                                for (let ind = 0; ind < this.form3[index].rowadd.length; ind++) {
                                    if (val.item_name === this.form3[index].rowadd[ind].val_item_name) {
                                        this.onQty(index, ind)
                                        this.form3[index].rowadd[ind].measurment = val.m1 + 'cm x ' + val.m2 + 'cm x ' + val.m3 + 'cm'
                                        this.form3[index].rowadd[ind].weight = val.weight
                                        this.form3[index].rowadd[ind].cbmTotal = val.m1 * val.m2 * val.m3
                                    }
                                }
                            })
                        }
                    });

                }
            }
        },

        validateAsync: function () {
            // Data Truck
            return new Promise((resolve, reject) => {

                if (this.form.truck_type === '' || this.form.truck_type === null) {
                    this.formRequired.truck_type = 'style-chooser'
                    reject(console.log('required'))
                } else {

                    this.formRequired.truck_type = false
                }

                if (this.form.container_size === '' || this.form.container_size === null) {
                    this.formRequired.container_size = 'style-chooser'
                    reject(console.log('required'))

                } else {
                    this.formRequired.container_size = false
                }

                if (this.form.choose_truck === '' || this.form.choose_truck === null) {
                    this.formRequired.choose_truck = 'style-chooser'
                    reject(console.log('required'))
                } else {
                    this.formRequired.choose_truck = false
                }

                if (this.form.seal_number === '' || this.form.seal_number === null) {
                    this.formRequired.seal_number = 'is-invalid'
                    reject(console.log('required'))
                } else {
                    this.formRequired.seal_number = false

                }

                if (this.form.container_number === '' || this.form.container_number === '') {
                    this.formRequired.container_number = 'is-invalid'
                    reject(console.log('required'))
                } else {
                    this.formRequired.container_number = false
                }

                if (this.form.driver_name === '' || this.form.driver_name === null) {
                    this.formRequired.driver_name = 'style-chooser'
                    reject(console.log('required'))
                } else {
                    this.formRequired.driver_name = false
                }
                // End Data Truck Validasi

                // Data Delivery Expence
                if (this.form.cash_bank === '' || this.form.cash_bank === null) {
                    this.formRequired.cash_bank = 'style-chooser'
                    reject(console.log('required'))
                } else {
                    this.formRequired.cash_bank = false
                }
                // if (this.form.autonumber === '' || this.form.autonumber === null) {
                //     this.formRequired.autonumber = 'style-chooser'
                //     reject(console.log('required'))
                // } else {
                //     this.formRequired.autonumber = false
                // }
                if (this.form.date === '' || this.form.date === null) {
                    this.formRequired.date = '__style-dete-required'
                    reject(console.log('required'))
                } else {
                    this.formRequired.date = false
                }
                if (this.form.amount_price === '' || this.form.amount_price === null) {
                    this.formRequired.amount = 'is-invalid'
                    reject(console.log('required'))
                } else {
                    this.formRequired.amount = false
                }
                if (this.form.remarks === '' || this.form.remarks === null) {
                    this.formRequired.remarks = 'is-invalid'
                    reject(console.log('required'))
                } else {
                    this.formRequired.remarks = false
                }
                // End Data Delivery Expence

                if (
                    // Validasi Truck
                    this.form.truck_type !== '' || this.form.truck_type !== null
                    && this.form.container_size !== '' || this.form.container_size !== null
                    && this.form.choose_truck !== '' || this.form.choose_truck !== null
                    && this.form.seal_number !== '' || this.form.seal_number !== null
                    && this.form.container_number !== '0' || this.form.container_number !== ''
                    && this.form.driver_name !== '' || this.form.driver_name !== null
                    && this.form.cash_bank === '' || this.form.cash_bank === null
                    // && this.form.autonumber === '' || this.form.autonumber === null
                    && this.form.date === '' || this.form.date === null
                    && this.form.amount === '' || this.form.amount === null
                    && this.form.remarks === '' || this.form.remarks === null
                    // End Validasi Truck

                ) {
                    resolve(true)

                }

            })
        },
        validateForm3Async: function () {
            // Data Truck
					if(this.form3.length > 0){
						return new Promise((resolve, reject) => {

									for (let index = 0; index < this.form3.length; index++) {
										if(this.form3[index].inputUnloadingAutonumber){
											if (this.form3[index].autonumber === '' || this.form3[index].autonumber === null ) {
													this.form3Required[index].inputUnloadingAutonumber = 'border-color-red'
													reject(console.log('required unloading ' ))
											} else {
													this.form3Required[index].inputUnloadingAutonumber = false
											}

											if (
													this.form3[index].autonumber !== '' || this.form3[index].autonumber !== null
											) {
													resolve(true)

											}
										}else{
											if (this.form3[index].autonumber_manual === '' || this.form3[index].autonumber_manual === null) {
													this.form3Required[index].inputUnloadingAutonumber = 'border-color-red'
													reject(console.log('required unloading '))
											} else {
													this.form3Required[index].inputUnloadingAutonumber = false
											}

											if (
												this.form3[index].autonumber_manual !== '' || this.form3[index].autonumber_manual !== null
											) {
													resolve(true)

											}
										}
									}

						})
					}else{
						return false;
					}
        },
				validateEmptyForm3: function(){
					return new Promise((resolve, reject) => {
						resolve(true)
					})
				},
        onCancelComplete() {
            this.$modal.hide('modal-finish');
            this.$bvModal.hide('modal-finish')
        },

        addLoading() {

            this.form2.push({
                loading: [],
                dal: '',
                name: '',
                rowadd: [
                    {
                        item_id: '',
                        item_set: true,
                        item_name: '',
                        m1: 0,
                        m2: 0,
                        m3: 0,
                        weight: 0,
                        qty: 0
                    },
                ]
            })

        },

        addUnloading() {
            // const unloading = this.form3
            // unloading.push(unloading.length + 1)
            this.form3.push({
                loading: [],
                unloading: [],
                autonumber: '',
                autonumber_manual: '',
                inputUnloadingAutonumber: true,
                dau: '',
                rowadd: [
                    {
                        item_name: [],
                        val_item_name: null,
                        val_item_id: null,
                        measurment: 0,
                        weight: 0,
                        qty: 0,
                        price: 0,
                        priceNumber: 0,
                        total: 0,
                        priceTotal: 0,
                        required_qty: false,
                        type: 'QTY',
                        cbmTotal: 0,
                        m1: '',
                        m2: '',
                        m3: ''
                    }
                ]
            })

            this.form3Required.push({
                inputUnloadingAutonumber: false
            })
        },
        onKembali() {
            window.history.back();
        },

        changeInputAutonumber() {
            this.form.autonumber_manual = ''
            this.form.autonumber = ''
            if (!this.inputAutonumber) {
                this.inputAutonumber = true;

            } else {
                this.inputAutonumber = false;

            }
        },

        onAmount() {
            let amount = this.form.amount_price.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.form.amount_price = 'Rp ' + amount
            this.form.amount = amount.toString().replace(/[^0-9.]/g, '')
        },

        nextSlice() {
            this.$refs.wizard.nextTab()
        },
        // list Request
        listTruckType() {
            try {
                this.getTypeOptions('data_type_truck')
                    .then((res) => {
                        this.truckType = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listContainerSize() {
            try {
                this.getTypeOptions('data_container')
                    .then((res) => {
                        this.continerSize = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listChooseTruck() {
            try {
                this.getTypeOptions('data_truck')
                    .then((res) => {
                        this.chooseTruck = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listDriverName() {
            try {
                this.getTypeOptions('data_driver_name')
                    .then((res) => {
                        this.driverName = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listCashBank() {
            try {
                this.getTypeOptions('data_cash_item')
                    .then((res) => {
                        this.cashBank = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }

        },
        listItemAccount() {
            try {
                this.getTypeOptions('data_item_account')
                    .then((res) => {
                        this.itemAccount = res
                        console.log(res, 'test')

                        for (let index = 0; index < res.length; index++) {
                            if (res[index].active === 'true') {
                                this.form.item_account = res[index].id
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listItem() {
            try {
                this.getTypeOptions('master_item')
                    .then((res) => {
                        this.itemMaster = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listMasterItem() {
            try {
                this.getTypeOptions('master_item')
                    .then((res) => {
                        this.masterItem = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listDeliveryExpenseAn() {
            try {
                this.getTypeOptions('delivery_expense')
                    .then((res) => {
                        this.deliveryExpenseAn = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        listUnloadingAn() {
            try {
                this.getTypeOptions('unloading_autonumber')
                    .then((res) => {
                        this.UnloadingAn = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },

        // Loading Function
        addRow(index) {
            this.form2[index].rowadd.push(
                {
                    item_id: '',
                    item_set: true,
                    item_name: '',
                    m1: 0,
                    m2: 0,
                    m3: 0,
                    weight: 0,
                    qty: 0
                },
            )

        },

        changeInputItemNameLoading(index, k) {
            if (!this.form2[index].rowadd[k].item_set) {
                this.form2[index].rowadd[k].item_set = true;
                this.form2[index].rowadd[k].item_name = '';
                this.form2[index].rowadd[k].item_id = '';

            } else {
                this.form2[index].rowadd[k].item_set = false;
                this.form2[index].rowadd[k].item_name = '';
                this.form2[index].rowadd[k].item_id = 0;

            }
        },
        totalMeasurment(index, k) {
            return this.form2[index].rowadd[k].m1 * this.form2[index].rowadd[k].m2 * this.form2[index].rowadd[k].m3
        },
        changeInputItemNameLoding(index, k) {
            if (!this.form2[index].rowadd[k].item_set) {
                this.form2[index].rowadd[k].item_set = true;
                this.form2[index].rowadd[k].item_name = '';
                this.form2[index].rowadd[k].item_id = 0;

            } else {
                this.form2[index].rowadd[k].item_set = false;
                this.form2[index].rowadd[k].item_name = '';
                this.form2[index].rowadd[k].item_id = 0;

            }
        },
        onQtyLoading(index, k) {
            this.form2[index].rowadd[k].qty = this.form2[index].rowadd[k].qty.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        },
        onMeasurmentLoading(index, k) {
            this.form2[index].rowadd[k].m1 = this.form2[index].rowadd[k].m1.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.form2[index].rowadd[k].m2 = this.form2[index].rowadd[k].m2.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.form2[index].rowadd[k].m3 = this.form2[index].rowadd[k].m3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        },
        onWeightLoading(index, k) {
            let weight = this.form2[index].rowadd[k].weight.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.form2[index].rowadd[k].weight = weight
        },

        totalMeasurmentCBMLoading(ind) {
            let sum = 0;
            for (let index = 0; index < this.form2[ind].rowadd.length; index++) {
                sum += ((this.form2[ind].rowadd[index].m1 * this.form2[ind].rowadd[index].m2 * this.form2[ind].rowadd[index].m3) / 1000000) * this.form2[ind].rowadd[index].qty
            }
            return sum.toFixed(2) + 'CBM';
        },
        totalQtyLoading(ind) {
            let qty = 0;
            for (let index = 0; index < this.form2[ind].rowadd.length; index++) {
                qty += parseInt(this.form2[ind].rowadd[index].qty)
            }
            return qty;

        },
        totalWeightLoading(ind) {
            let sum = 0;
            for (let index = 0; index < this.form2[ind].rowadd.length; index++) {
                sum += parseInt(this.form2[ind].rowadd[index].weight) * parseInt(this.form2[ind].rowadd[index].qty)
            }
            return sum;
        },
        getNameItem(value, index, k) {
            try {
                this.getTypeOptions('master_item?id=' + value)
                    .then((res) => {
                        this.form2[index].rowadd[k].item_name = res[0].name
                        // console.log(value + ',' + index)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        // Unloading Funtion
        addFormUnloading(index) {
            this.form3[index].rowadd.push({
                item_name: [],
                val_item_name: null,
                val_item_id: null,
                measurment: '',
                weight: 0,
                qty: 0,
                price: 0,
                priceNumber: 0,
                total: 0,
                priceTotal: 0,
                required_qty: false,
                type: 'QTY',
                cbmTotal: '',
                m1: '',
                m2: '',
                m3: ''
            })
            this.loading.forEach(element => {
                if (element.loading === this.form3[index].loading) {
                    for (let ind = 0; ind < this.form3[index].rowadd.length; ind++) {
                        this.form3[index].rowadd[ind].item_name = element.rowadd
                    }
                }
            });
        },
				deleteFormLoading(event, loading) {
            this.itemloadings.splice(event, 1)
            this.$delete(this.form2, event)
						if(this.$route.name === 'JobOrder Edit Distribution'){
							this.formDelete.push({
								id_distirbution_loading: loading.id_distirbution_loading
							})
						}
        },

        removeRow(index, k,row) {
					this.form2[index].rowadd.splice(k, 1)
					console.log(index)
					if(this.$route.name === 'JobOrder Edit Distribution'){
						this.formDelete.push({
							id_distirbution_loading_item: row.id_distirbution_loading_item
						})
					}
        },

				deleteFormUnloading(event,unloading) {
            // this.itemunloadings.splice(event, 1)
            this.$delete(this.form3, event)
						// console.log(unloading)
						if(this.$route.name === 'JobOrder Edit Distribution'){
							this.formDelete.push({
								id_distirbution_unloading: unloading.id_distirbution_unloading
							})
						}
        },

        removeFormUnloading(index, k,row) {
            this.form3[index].rowadd.splice(k, 1)
						if(this.$route.name === 'JobOrder Edit Distribution'){
						this.formDelete.push({
							id_distirbution_unloading_item: row.id_distirbution_unloading_item
						})
					}
        },

        changeInputUnloadingAutonumber(index) {
            if (this.$route.name !== 'JobOrder Edit Distribution') {
                this.form3[index].autonumber_manual = ''
            }
            this.form3[index].autonumber = ''
            if (!this.form3[index].inputUnloadingAutonumber) {
                this.form3[index].inputUnloadingAutonumber = true;
            } else {
                this.form3[index].inputUnloadingAutonumber = false;
            }



        },
        onQty(k, index) {

            const mapData = this.form3.map(item => {
                const mapDataItem = item.rowadd.map(items => {
                    const container = {};
                    container.item = items.qty;
                    container.item_name = items.val_item_name
                    container.loading = items.loading
                    container.qty_max = this.getLoadingMaxQty(items.loading, items.val_item_name)
                    return container;
                })
                return mapDataItem;
            })

            let arr = [];
            mapData.forEach(item => {
                item.forEach(o => arr.push(o))
            })

            var result = [];
            arr.reduce(function (res, value) {
                if (!res[value.item_name + '_' + value.loading]) {
                    res[value.item_name + '_' + value.loading] = { item_name: value.item_name, item: 0, loading: value.loading, qty_max: value.qty_max };
                    result.push(res[value.item_name + '_' + value.loading])
                }
                res[value.item_name + '_' + value.loading].item += parseInt(value.item);

                return res;
            }, {});

            console.log(result)

            result.forEach(i => {
                // console.log(this.form3[index].loading + '-' + i.loading)
                if (i.loading === this.form3[index].rowadd[k].loading) {
                    if (i.item_name === this.form3[index].rowadd[k].val_item_name) {
                        if (i.item > i.qty_max) {
                            this.form3[index].rowadd[k].required_qty = 'is-invalid'
                        } else {
                            this.form3[index].rowadd[k].required_qty = ''
                        }
                    }
                }
            });

            this.onTotal(index, k)

        },
        getLoadingMaxQty(loading, k) {
            let qty = 0;
            this.loading.forEach(element => {
                if (element.loading === loading) {
                    element.rowadd.forEach(val => {
                        if (k === val.item_name) {
                            qty = val.qty
                        }
                    })
                }
            });
            return qty

        },
        onPrice(index, k) {
            let price = this.form3[index].rowadd[k].price.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            this.form3[index].rowadd[k].price = 'Rp ' + price
            this.form3[index].rowadd[k].priceNumber = price.toString().replace(/[^0-9.]/g, '')
            this.onTotal(index, k)
        },
        listAddressLoading() {
            try {
                this.getTypeOptions('address_landing')
                    .then((res) => {
                        this.optionUnloading = res
                        this.optionLoading = res
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        setSelectedLoading(value, index) {
            try {
                this.getTypeOptions('address_landing_details?id=' + value)
                    .then((res) => {
                        // console.log(res)
                        this.form2[index].dal = res[0].address
                        this.form2[index].name = res[0].name
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        setSelectedUnloading(value, index) {
            try {
                this.getTypeOptions('address_landing_details?id=' + value)
                    .then((res) => {
                        // console.log(res)
                        this.form3[index].dau = res[0].address
                        this.form3[index].name = res[0].name
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } catch (error) {
                console.log(error)
            }
        },
        setSelectedLoadingInUnloading(index, k) {
            this.getLoading(index,k)
        },
        getLoading(index,k) {
            this.loading.forEach(element => {
                if (element.loading === this.form3[index].rowadd[k].loading) {
									console.log(element.rowadd)
                    // for (let ind = 0; ind < this.form3[index].rowadd.length; ind++) {
												this.form3[index].rowadd[k].val_item_id = ''
                        this.form3[index].rowadd[k].item_name = element.rowadd
                        this.form3[index].rowadd[k].measurment = ''
                        this.form3[index].rowadd[k].weight = ''
                        this.form3[index].rowadd[k].val_item_name = ''
                        this.form3[index].rowadd[k].cbmTotal = ''
                        this.form3[index].rowadd[k].m1 = ''
                        this.form3[index].rowadd[k].m2 = ''
                        this.form3[index].rowadd[k].m3 = ''
                    }
                // }
            });
        },
        getMeasurmentLoading(index, k) {
            this.loading.forEach(element => {
                if (element.loading ===this.form3[index].rowadd[k].loading) {
                    element.rowadd.forEach(val => {
                        for (let ind = 0; ind < this.form3[index].rowadd.length; ind++) {
                            if (val.item_id === this.form3[index].rowadd[ind].val_item_id) {
                                this.form3[index].rowadd[ind].measurment = val.m1 + 'Cm x' + val.m2 + 'Cm x' + val.m3 + 'Cm'
                                this.form3[index].rowadd[ind].weight = val.weight
                                this.form3[index].rowadd[ind].val_item_name = val.item_name
                                this.form3[index].rowadd[ind].cbmTotal = val.m1 * val.m2 * val.m3
                                this.form3[index].rowadd[ind].m1 = val.m1
                                this.form3[index].rowadd[ind].m2 = val.m2
                                this.form3[index].rowadd[ind].m3 = val.m3
                            }
                        }
                    })
                }
            });
        },
        totalQty(ind) {
            let qty = 0;
            for (let index = 0; index < this.form3[ind].rowadd.length; index++) {
                qty += parseInt(this.form3[ind].rowadd[index].qty)
            }
            if (isNaN(qty)) {
                return 0
            } else {
                return qty;
            }
        },
        onTotal(index, k) {
            console.log(this.form3[index].rowadd[k])
            let total = 0;

            switch (this.form3[index].rowadd[k].type) {
                case 'QTY':
                    total = this.form3[index].rowadd[k].priceNumber.replace(/[^0-9.]/g, '') * this.form3[index].rowadd[k].qty
                    this.form3[index].rowadd[k].measurment = this.form3[index].rowadd[k].m1 + 'Cm x' + this.form3[index].rowadd[k].m2 + 'Cm x' + this.form3[index].rowadd[k].m3 + 'Cm'
                    break;
                case 'KGS':
                    total = this.form3[index].rowadd[k].priceNumber.replace(/[^0-9.]/g, '') * (this.form3[index].rowadd[k].weight * this.form3[index].rowadd[k].qty)
                    this.form3[index].rowadd[k].measurment = this.form3[index].rowadd[k].m1 + 'Cm x' + this.form3[index].rowadd[k].m2 + 'Cm x' + this.form3[index].rowadd[k].m3 + 'Cm'
                    break;
                case 'CBM':
                    total = ((this.form3[index].rowadd[k].priceNumber.replace(/[^0-9.]/g, '') * ((parseInt(this.form3[index].rowadd[k].cbmTotal) / 1000000) * this.form3[index].rowadd[k].qty)))
                    this.form3[index].rowadd[k].measurment = this.form3[index].rowadd[k].m1 + 'Cm x' + this.form3[index].rowadd[k].m2 + 'Cm x' + this.form3[index].rowadd[k].m3 + 'Cm'
                    break;
                default:
                // code block
            }


            this.form3[index].rowadd[k].priceTotal = total;
            if (isNaN(total)) {
                this.form3[index].rowadd[k].total = 0;
            } else {
                let replace = 'Rp ' + total.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                this.form3[index].rowadd[k].total = replace
            }
        },
        totalPrice(ind) {
            let price = 0;
            for (let index = 0; index < this.form3[ind].rowadd.length; index++) {
                price += parseInt(this.form3[ind].rowadd[index].priceTotal)
            }

            if (isNaN(price)) {
                return 0
            } else {
                return 'Rp ' + price.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        totalMeasurmentCBM(ind) {
            let sum = 0;
            for (let index = 0; index < this.form3[ind].rowadd.length; index++) {
                sum += (this.form3[ind].rowadd[index].cbmTotal / 1000000) * this.form3[ind].rowadd[index].qty
            }
            return sum.toFixed(2) + 'CBM';
        },
        totalWeight(ind) {
            let sum = 0;
            for (let index = 0; index < this.form3[ind].rowadd.length; index++) {
                sum += parseInt(this.form3[ind].rowadd[index].weight) * parseInt(this.form3[ind].rowadd[index].qty)
            }
            return sum;
        },

        // edit
        getDataDistribution() {
            axios.get(this.$store.state.rootApi + "/distribution/edit/" + this.$route.params.id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    this.inputAutonumber = false
                    this.inputUnloadingAutonumber = false
                    this.form.truck_type = parseInt(res.data[0].truck_type)
                    this.form.container_size = res.data[0].container_size
                    this.form.choose_truck = parseInt(res.data[0].choose_truck)
                    this.form.seal_number = res.data[0].seal_number
                    this.form.container_number = res.data[0].container_number
                    this.form.driver_name = parseInt(res.data[0].driver_name)
                    this.form.cash_bank = parseInt(res.data[0].cash_or_bank)
                    this.form.autonumber_manual = res.data[0].number_delivery
                    this.form.date = res.data[0].date
                    this.form.amount_price = 'Rp ' + res.data[0].amount.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    this.form.amount = res.data[0].amount
                    this.form.item_account = res.data[0].item_account
                    this.form.memo = res.data[0].memo
                    this.form.remarks = res.data[0].remarks

                    // loading
                    let loading = res.data[0].job_order_distribution_loading
                    let totalloading = loading.length

                    for (let iLoading = 0; iLoading < totalloading; iLoading++) {
                        let dtForm2 = loading[iLoading]
                        this.form2.push({
                            id_distirbution_loading: dtForm2.id,
                            loading: dtForm2.loading_id,
                            dal: dtForm2.detail_address_loading,
                            name: '',
                            rowadd: []
                        })

                        this.getTypeOptions('address_landing_details?id=' + dtForm2.loading_id)
                            .then((res) => {
                                this.form2[iLoading].name = res[0].name
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                        this.getDataForm2Rowadd(iLoading, dtForm2)
                    }
                    this.loading = JSON.parse(JSON.stringify(this.form2))
                    // unloading
                    let unloading = res.data[0].job_order_distribution_unloading
                    let totalunloading = unloading.length


                    for (let iUnloading = 0; iUnloading < totalunloading; iUnloading++) {
                        let dtForm3 = unloading[iUnloading]
                        this.form3.push({
                            inputUnloadingAutonumber: false,
                            id_distirbution_unloading: dtForm3.id,
                            loading: dtForm3.loading_id,
                            unloading: dtForm3.unloading_id,
                            autonumber_manual: dtForm3.autonumber,
                            dau: dtForm3.details_address_unloading,
                            rowadd: []
                        })
                        this.getDataForm3Rowadd(iUnloading, dtForm3)

												this.form3Required.push({
										inputUnloadingAutonumber: false
								})
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.errorAlert('Failed save data')
                })

        },
        getDataForm2Rowadd(index, dtForm2) {
            let totalItemLoading = dtForm2.job_order_distribution_loading_item.length
            let itemLoading = dtForm2.job_order_distribution_loading_item
            let dataType

            for (let iLoading = 0; iLoading < totalItemLoading; iLoading++) {
                if (itemLoading[iLoading].item_id !== 0) {
                    dataType = true
                } else {
                    dataType = false
                }

                this.form2[index].rowadd.push({
                    id_distirbution_loading_item: itemLoading[iLoading].id,
                    item_id: itemLoading[iLoading].item_id,
                    item_set: dataType,
                    item_name: itemLoading[iLoading].item_name,
                    m1: itemLoading[iLoading].measurment_1,
                    m2: itemLoading[iLoading].measurment_2,
                    m3: itemLoading[iLoading].measurment_3,
                    weight: itemLoading[iLoading].weight,
                    qty: itemLoading[iLoading].qty
                })

            }
        },

        getDataForm3Rowadd(index, dtForm3) {
            let totalItemunloading = dtForm3.job_order_distribution_unloading_item.length
            let itemUnloading = dtForm3.job_order_distribution_unloading_item

            for (let iUnloading = 0; iUnloading < totalItemunloading; iUnloading++) {
                this.form3[index].rowadd.push({
									  loading: itemUnloading[iUnloading].loading_id,
                    item_name: [],
                    id_distirbution_unloading_item: itemUnloading[iUnloading].id,
                    val_item_id: itemUnloading[iUnloading].item_id,
                    val_item_name: itemUnloading[iUnloading].item_name,
                    measurment: itemUnloading[iUnloading].measurment,
                    weight: itemUnloading[iUnloading].weight,
                    qty: itemUnloading[iUnloading].qty,
                    price: 'Rp ' + itemUnloading[iUnloading].price.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    priceNumber: itemUnloading[iUnloading].price.toString(),
                    total: 'Rp ' + itemUnloading[iUnloading].total.toString().replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    priceTotal: itemUnloading[iUnloading].total.toString(),
                    required_qty: false,
                    type: itemUnloading[iUnloading].type,
                    cbmTotal: '',
                    m1: '',
                    m2: '',
                    m3: '',
                })
                // this.onPrice(index, iUnloading)
							}


							this.loading.forEach(element => {
								element.rowadd.forEach(val => {
									for (let ind = 0; ind < this.form3[index].rowadd.length; ind++) {
										if (val.item_id === this.form3[index].rowadd[ind].val_item_id) {
											this.form3[index].rowadd[ind].m1 = val.m1
											this.form3[index].rowadd[ind].m2 = val.m2
											this.form3[index].rowadd[ind].m3 = val.m3
											this.onTotal(index,ind)

									}
													if (element.loading === this.form3[index].rowadd[ind].loading) {
                            this.form3[index].rowadd[ind].item_name = element.rowadd

													}
                        }
								})
            });
        }

    }
}
</script>

<style>
.btn-cancel {
    /* width: 90px; */
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 34px;
    background-color: #E0E0E0;
    color: #817F7F;
    border: 0;
    border-radius: 3px;
}

.btn-next {
    /* width: 90px; */
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 34px;
    background-color: #51C1AD !important;
    color: #fff;
    border: 0;
    border-radius: 3px;
}

.btn-finish {
    /* width: 120px; */
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 34px;
    background-color: #51C1AD;
    color: #fff;
    border: 0;
    border-radius: 3px;
}

.add-loading {
    background-color: #FFF2D9;
    border-radius: 5px;
    padding: 1em;
}

.add-unloading {
    background-color: #FFF2D9;
    border-radius: 5px;
    padding: 1em;
}

.btn-new-transaction {
    background-color: #F9A707;
    margin-right: -3.5rem;
}

.btn-new-transaction:hover {
    color: white;
}

.box {
    height: 200px;
    width: 100%;
    background-color: white;
    margin: 0 auto;
    top: 200px;
    text-align: center;
    align-content: center;
    margin-top: 120px;
}

.image-truck {
    width: 60px;
    margin-left: 35px;
}

.wizard-icon-container i {
    font-style: normal;
}

.wizard-icon-circle i {
    font-style: normal;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    border-color: #f1556c;
}

.__style-dete-required {
    border: 1px solid #f1556c;
    border-radius: 4px;
}

.border-color-red {
    border-color: #f1556c;

}

/* #distribution .general-info svg {
    color: #F9A707;
} */


/* .wizard-icon-container i {
        font-style: normal;
    }

    .wizard-icon-circle i {
        font-style: normal;
    }
    .wizard-icon-container {
        border-radius: 50% !important;
    }

    .wizard-progress-bar li:first-child:after {
        content: none;
    }

    .general-info svg {
        color: #51c1ad;
    }

    .wizard-progress-bar {
        width: (100 / 3) * (5 - 1) '%';
    } */
</style>
